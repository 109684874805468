import {
  useTrackCompletedOnboardingPurposeOfUse,
  useTrackCompletedOnboardingSize,
  useTrackCompletedOnboardingSource,
  useTrackViewedOnboardingPurposeOfUse,
  useTrackViewedOnboardingSize,
  useTrackViewedOnboardingSource,
} from '@air/analytics';
import { Workspaces } from '@air/api';
import { Onboarding } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { reportErrorToBugsnag } from '@air/utils-error';
import { Formik, FormikProps } from 'formik';
import { noop } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useRef } from 'react';
import { useMount } from 'react-use';
import * as Yup from 'yup';

import Form from '~/components/Form';
import { FormikSingleSelect } from '~/components/Formik/FormikSingleSelect';
import {
  ONBOARDING_MODAL,
  ONBOARDING_PRIMARY_PURPOSE_DETAILS_INPUT,
  ONBOARDING_REFERRAL_SOURCE_DETAILS_INPUT,
  ONBOARDING_WORKSPACE_INFO_SUBMIT,
} from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { OnboardingQuestionsField, onboardingQuestionsFields } from '~/utils/Onboarding';
import { canChangeWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

const WorkspaceInfoValidationSchema = Yup.object()
  .shape({
    [OnboardingQuestionsField.purposeOfUseFieldName]: Yup.string().default('').required('Purpose of use is required'),
  })
  .shape({
    [OnboardingQuestionsField.companySizeFieldName]: Yup.string().default('').required('Company size is required'),
  })
  .shape({ [OnboardingQuestionsField.referralSourceFieldName]: Yup.string().default('') })
  .shape({ referralSourceDetails: Yup.string().default('') })
  .shape({
    purposeOfUseDetails: Yup.string()
      .when(OnboardingQuestionsField.purposeOfUseFieldName, {
        is: 'Other',
        then: (schema) => schema.required('Purpose of use details are required'),
      })
      .default(''),
  })
  .required();

const initialValues = WorkspaceInfoValidationSchema.cast({});

export type OnboardingModalProps = AirModalProps;

export const OnboardingModal = ({ onClose }: OnboardingModalProps) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null);
  const { trackViewedOnboardingPurposeOfUse } = useTrackViewedOnboardingPurposeOfUse();
  const { trackViewedOnboardingSize } = useTrackViewedOnboardingSize();
  const { trackViewedOnboardingSource } = useTrackViewedOnboardingSource();
  const { trackCompletedOnboardingPurposeOfUse } = useTrackCompletedOnboardingPurposeOfUse();
  const { trackCompletedOnboardingSource } = useTrackCompletedOnboardingSource();
  const { trackCompletedOnboardingSize } = useTrackCompletedOnboardingSize();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();
  const { query, replace } = useRouter();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  useMount(() => {
    trackViewedOnboardingPurposeOfUse();
    trackViewedOnboardingSource();
    trackViewedOnboardingSize();
  });

  const uploadOnboardingData = useCallback(
    async (onboardingData: Required<Omit<Onboarding, 'statedMemberRole'>> & { didSkip: boolean }) => {
      trackCompletedOnboardingPurposeOfUse({
        answer: onboardingData.statedWorkspaceUse,
        skipped: onboardingData.didSkip,
      });
      trackCompletedOnboardingSize({ answer: onboardingData.statedCompanySize, skipped: onboardingData.didSkip });
      trackCompletedOnboardingSource({ answer: onboardingData.statedReferralSource, skipped: onboardingData.didSkip });

      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      try {
        await Workspaces.createOnboardingData({ workspaceId, onboardingData });
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: 'Failed to send onboarding data',
          metadata: {
            data: {
              workspaceId,
              onboardingData,
            },
          },
        });
      }
    },
    [trackCompletedOnboardingPurposeOfUse, trackCompletedOnboardingSize, trackCompletedOnboardingSource, workspaceId],
  );

  if (!canChangeWorkspaceDetails(permissions)) {
    return null;
  }

  return (
    <Modal isOpen onDismiss={noop} data-testid={ONBOARDING_MODAL}>
      <header className="flex items-center justify-between gap-2">
        <ModalTitle>Welcome to Air!</ModalTitle>
        <ModalCloseButton onClick={onClose} />
      </header>
      <ModalDescription className="mt-6">
        Personalize your onboarding experience by answering the questions below.
      </ModalDescription>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={WorkspaceInfoValidationSchema}
        onSubmit={async (values) => {
          const isEmpty = Object.values(values).join('') === '';

          uploadOnboardingData({
            statedWorkspaceUse:
              (values.purposeOfUse === 'Other' ? values.purposeOfUseDetails : values.purposeOfUse) ?? '',
            statedCompanySize: values.companySize ?? '',
            statedReferralSource:
              (values.referralSource === 'Other' ? values.referralSourceDetails : values.referralSource) ?? '',
            didSkip: isEmpty,
          });

          const queryParams = { ...query };
          delete queryParams['showOnboarding'];

          replace(
            {
              query: queryParams,
            },
            undefined,
            {
              shallow: true,
            },
          );

          onClose();
        }}
      >
        {({ values }) => (
          <Form>
            {onboardingQuestionsFields.map(({ name, placeholder, options, subHeader, testId }) => {
              if (name === OnboardingQuestionsField.referralSourceFieldName) {
                return (
                  <div className="mb-7 mt-4 flex flex-col gap-2" key={name}>
                    <FormikSingleSelect
                      label={subHeader}
                      name={name}
                      placeholder={placeholder}
                      options={options}
                      size="extra-large"
                      data-testid={testId}
                    />
                    {values.referralSource === 'Other' && (
                      <FormikField
                        id="referralSourceDetails"
                        isLabelHidden
                        label="Please add more details"
                        name="referralSourceDetails"
                        component={
                          <Input
                            size="extra-large"
                            placeholder="Please add more details"
                            data-testid={ONBOARDING_REFERRAL_SOURCE_DETAILS_INPUT}
                          />
                        }
                      />
                    )}
                  </div>
                );
              } else if (name === OnboardingQuestionsField.purposeOfUseFieldName) {
                return (
                  <div className="mb-7 mt-4 flex flex-col gap-2" key={name}>
                    <FormikSingleSelect
                      label={subHeader}
                      name={name}
                      placeholder={placeholder}
                      options={options}
                      size="extra-large"
                      data-testid={testId}
                    />

                    {values.purposeOfUse === 'Other' && (
                      <FormikField
                        id="purposeOfUseDetails"
                        isLabelHidden
                        label="Please add more details *"
                        name="purposeOfUseDetails"
                        component={
                          <Input
                            size="extra-large"
                            placeholder="Please add more details"
                            data-testid={ONBOARDING_PRIMARY_PURPOSE_DETAILS_INPUT}
                          />
                        }
                      />
                    )}
                  </div>
                );
              }

              return (
                <div className="my-4 mb-7" key={name}>
                  <FormikSingleSelect
                    label={subHeader}
                    name={name}
                    placeholder={placeholder}
                    options={options}
                    size="extra-large"
                    data-testid={testId}
                  />
                </div>
              );
            })}
            <Button
              appearance="filled"
              color="blue"
              className="w-full"
              type="submit"
              size="extra-large"
              data-testid={ONBOARDING_WORKSPACE_INFO_SUBMIT}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
