import { useAirModal } from '@air/provider-modal';
import { useSysadmin } from '@air/sysadmin';
import { isBoolean, isString } from 'lodash';
import Router from 'next/router';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { InvalidWorkspace } from '~/components/Modals/Internal/InvalidWorkspace';
import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { useSwitchWorkspace } from '~/hooks/useSwitchWorkspace';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { workspaceIdQueryParamSelector } from '~/store/router/selectors';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';
import { getQueryWithoutParams } from '~/utils/PathUtils';

/**
 * This hook switches current workspace based on the workspace query parameter "wsid".
 */
export const useWorkspaceQueryParam = (redirectPath = Routes.home) => {
  const { data: workspaces } = useWorkspaces();
  const { currentWorkspace } = useCurrentWorkspace();
  const { switchWorkspace } = useSwitchWorkspace();
  const otherWorkspaces = workspaces?.filter(({ id }) => id !== currentWorkspace?.id);
  const [showInvalidWorkspaceModal] = useAirModal(InvalidWorkspace);
  const switchedRef = useRef(false);
  const { isInternal, handleSetWorkspaceId } = useSysadmin();

  const workspaceIDQueryParameter = useSelector(workspaceIdQueryParamSelector);

  const isValidWorkspaceID = otherWorkspaces?.some(({ id }) => workspaceIDQueryParameter === id);

  useEffect(() => {
    if (
      !!workspaceIDQueryParameter &&
      !switchedRef.current &&
      isBoolean(isValidWorkspaceID) &&
      isString(workspaceIDQueryParameter)
    ) {
      switchedRef.current = true;

      const queryWithoutWSID = getQueryWithoutParams(window.location.search, [QueryParamNames.workspaceId]).toString();
      if (isValidWorkspaceID) {
        switchWorkspace({ id: workspaceIDQueryParameter });
        Router.replace({ pathname: redirectPath, query: queryWithoutWSID }, undefined, { shallow: false });
      } else if (workspaceIDQueryParameter === currentWorkspace?.id) {
        Router.replace({ pathname: redirectPath, query: queryWithoutWSID }, undefined, { shallow: false });
      } else {
        showInvalidWorkspaceModal();
      }
    } else if (isInternal && !!workspaceIDQueryParameter) {
      const queryWithoutWSID = getQueryWithoutParams(window.location.search, [QueryParamNames.workspaceId]).toString();

      handleSetWorkspaceId(workspaceIDQueryParameter);

      Router.replace({ pathname: redirectPath, query: queryWithoutWSID }, undefined, { shallow: false });
    }
  }, [
    currentWorkspace?.id,
    handleSetWorkspaceId,
    isInternal,
    isValidWorkspaceID,
    otherWorkspaces,
    redirectPath,
    showInvalidWorkspaceModal,
    switchWorkspace,
    workspaceIDQueryParameter,
  ]);
};
