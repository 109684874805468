import { Router } from 'next/router';
import { useEffect, useRef } from 'react';

export const useLocationDidChange = (pathname: string) => {
  const didChangeRef = useRef(false);

  useEffect(() => {
    const routeChangeStartHandler = (newPathname: string) => {
      // we create new URLs so that we can grab the exact pathname without any query params (newPathname comes with the query params)
      // we don't want to flag a change if just the query params changed
      const originUrl = new URL(`${window.location.origin}${pathname}`);
      const newUrl = new URL(`${window.location.origin}${newPathname}`);

      if (!didChangeRef.current && originUrl.pathname !== newUrl.pathname) {
        didChangeRef.current = true;
      }
    };

    Router.events.on('routeChangeStart', routeChangeStartHandler);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStartHandler);
    };
  }, [pathname]);

  return {
    locationDidChange: () => didChangeRef.current,
  };
};
