import { Boards } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const DEFAULT_BOARD = 'DEFAULT_BOARD';

export const getDefaultBoardKey = (workspaceId?: string) =>
  workspaceId ? [DEFAULT_BOARD, { workspaceId }] : [DEFAULT_BOARD];

export const useGetDefaultBoard = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery({
    queryKey: getDefaultBoardKey(currentWorkspace?.id),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      return Boards.getDefaultBoard({ workspaceId });
    },
    enabled: !!workspaceId,
  });
};
