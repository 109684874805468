import { Constants } from '@air/api';
import { shuffle } from 'lodash';

import { SelectProps } from '~/components/Select/Select';
import {
  ONBOARDING_COMPANY_SIZE_SELECT,
  ONBOARDING_PRIMARY_PURPOSE_SELECT,
  ONBOARDING_REFERRAL_SOURCE_SELECT,
} from '~/constants/testIDs';

export enum OnboardingQuestionsField {
  purposeOfUseFieldName = 'purposeOfUse',
  companySizeFieldName = 'companySize',
  referralSourceFieldName = 'referralSource',
}

const mapToSelectOptions = (arrayOfStrings: string[]): SelectProps['options'] =>
  arrayOfStrings.map((value) => ({ label: value, value }));

const customOption = 'Other';

const purposeOfUseOptions = [
  ...shuffle(Constants.Onboarding.PurposeOfUse).filter((option) => option !== customOption),
  customOption,
];

const companySizeOptions = Constants.Onboarding.CompanySize ?? [];

const referralOptions = [
  ...shuffle(Constants.Onboarding.ReferralSource).filter((option) => option !== customOption),
  customOption,
];

export const onboardingQuestionsFields: {
  name: OnboardingQuestionsField;
  subHeader: string;
  label: string;
  placeholder: string;
  options: SelectProps['options'];
  testId: string;
}[] = [
  {
    name: OnboardingQuestionsField.purposeOfUseFieldName,
    subHeader: 'What are you hoping to use Air for? *',
    label: 'Primary purpose',
    placeholder: 'Select a primary purpose...',
    options: mapToSelectOptions(purposeOfUseOptions),
    testId: ONBOARDING_PRIMARY_PURPOSE_SELECT,
  },
  {
    name: OnboardingQuestionsField.companySizeFieldName,
    subHeader: 'What is your company size? *',
    label: 'Company size',
    placeholder: 'Select a company size...',
    options: mapToSelectOptions(companySizeOptions),
    testId: ONBOARDING_COMPANY_SIZE_SELECT,
  },
  {
    name: OnboardingQuestionsField.referralSourceFieldName,
    subHeader: 'How did you first hear about us?',
    label: 'referral',
    placeholder: 'Select a source...',
    options: mapToSelectOptions(referralOptions),
    testId: ONBOARDING_REFERRAL_SOURCE_SELECT,
  },
];
